import React from "react"
import { Link } from "gatsby"
import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
   <Container className="d-flex mt-5 justify-content-center align-content-center">
    <Card className="w-50 m-3 p-3">
    <Card.Header className="m-3 p-3 text-center"><h3>404 - Page Not Found</h3></Card.Header>
    <Card.Body className="m-3 p-3 text-center">
      You can't ask the tarot any questions on this page.
      The page you're after has either been deleted or does't exist.
    </Card.Body>
    
    <Card.Footer className="d-flex justify-content-center m-3 p-3">
      <Button className="w-75 m-1 p-3" style={{borderRadius: `1rem`}}>
        <Link to="/">Return to the Site</Link>
      </Button>
    </Card.Footer>
    </Card>
    </Container>
  </>
)

export default NotFoundPage
